<template>
  <div class="menopause-timeline-container mb-12">
    <h3 class="text-overline mr-2">Most common menopause timeline</h3>
    <div class="menopause-indicator">
      <div class="menopause-indicator-icon">
        <svg
          width="32"
          height="71"
          viewBox="0 0 32 71"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="4" y="4" width="2" height="67" rx="1" fill="#936B2E" />
          <path
            d="M32 6.40234H2V26.4023H32L24.4138 16.4023L32 6.40234Z"
            fill="#005F61"
          />
        </svg>
      </div>
      <h4 class="text-body-bold text-body-2 vflPrimary--text">Menopause</h4>
      <p class="text-caption vflNeutralDark2--text mb-0">
        12 months after your periods stop<br />Age 51 on average
      </p>
    </div>
    <ul>
      <li>
        <h4 class="text-body-bold text-body-2 vflPrimary--text">Periods</h4>
        <p class="text-caption vflNeutralDark2--text mt-2 mb-0">
          As usual, no changes
        </p>
      </li>
      <li>
        <h4 class="text-body-bold text-body-2 vflPrimary--text">
          Perimenopause
        </h4>
        <p class="text-caption vflNeutralDark2--text mt-2 mb-0">
          Change in periods,<br />
          menopausal symptoms
        </p>
        <p class="text-caption vflNeutralDark2--text mt-2 mb-0">
          Typically begins in your 40s
        </p>
      </li>
      <li>
        <h4 class="text-body-bold text-body-2 vflPrimary--text v">
          Postmenopause
        </h4>
        <p class="text-caption vflNeutralDark2--text mt-2 mb-0">
          No further periods,<br />
          symptoms may persist
        </p>
        <p class="text-caption vflNeutralDark2--text mt-2 mb-0">
          Usually starts around 52
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "MenopauseTimeline"
};
</script>

<style lang="scss" scoped>
.menopause-timeline-container {
  container-type: inline-size;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  > h3 {
    grid-column: 1 / 3;
    line-height: 1.2;
    padding-top: 15px;
  }

  .menopause-indicator {
    display: grid;
    grid-column: 3;
    grid-column-gap: 0.5rem;
    grid-template-columns: min-content auto;
    grid-template-rows: min-content auto;
    transform: translate3d(-4px, 4px, 0);

    &-icon {
      grid-column: 1;
      grid-row: 1/3;
    }

    h4 {
      grid-column: 2;
      padding-top: 0.5rem;
      line-height: 1;
    }

    p {
      grid-column: 1/3;
      grid-row: 2;
      padding-left: 16px;
      line-height: 1.25;
    }
  }

  ul {
    border-radius: 12px;
    display: grid;
    grid-column: 1 / -1;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 0;
    overflow: hidden;
    padding: 0;
  }

  li {
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    list-style: none;
    padding: 1rem;

    > * {
      position: relative;
      z-index: 1;
    }

    &:first-child {
      background: #f1dcea;
      position: relative;

      &:after {
        background-image: linear-gradient(to right, #f1dcea, #fdebd8);
        bottom: 0;
        content: "";
        pointer-events: none;
        position: absolute;
        right: -10px;
        top: 0;
        width: 40px;
        z-index: 0;
      }
    }

    &:nth-child(2) {
      background: #fdebd8;
    }

    &:nth-child(3) {
      background: #c2edd7;
    }

    p {
      line-height: 1.25;
    }
  }
}
</style>
