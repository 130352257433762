<template>
  <div v-if="useFigmaIframe" class="iframe-container">
    <iframe
      src="https://embed.figma.com/proto/wRGy5cZ70MQ2LG1R4NAxTp/Menopause-report?page-id=2993%3A54651&node-id=2993-72898&node-type=frame&viewport=508%2C3118%2C0.16&scaling=scale-down-width&content-scaling=fixed&starting-point-node-id=2993%3A72898&hotspot-hints=0&embed-host=share"
      allowfullscreen
    ></iframe>
  </div>
  <section v-else>
    <template v-for="section in visibleSections">
      <component
        :key="section.id"
        :is="section.component"
        v-bind="section.props"
        :results="results"
        :assessmentId="assessmentId"
        :sectionNumber="section.sectionNumber"
        :backgroundColor="section.backgroundColor"
      />
    </template>

    <report-footer
      :assessmentId="assessmentId"
      :assessmentType="assessmentType"
      :reportContext="reportContext"
    />
  </section>
</template>

<script>
import { assessmentTypes } from "@/constants/constants.js";
import ReportFooter from "@/components/common/report/ReportFooter.vue";
import MenopauseReportHeader from "@/components/assessment/menopause/report/MenopauseReportHeader.vue";
import MenopauseReportMskHealth from "@/components/assessment/menopause/report/MenopauseReportMskHealth.vue";
import MenopauseReportMuscleLoss from "@/components/assessment/menopause/report/MenopauseReportMuscleLoss.vue";
import MenopauseReportPain from "@/components/assessment/menopause/report/MenopauseReportPain.vue";
import MenopauseReportSymptoms from "@/components/assessment/menopause/report/MenopauseReportSymptoms.vue";
import { mapGetters } from "vuex";
import { REPORT_SECTION_CONFIG } from "@/components/assessment/menopause/report/constants/index.js";

const BACKGROUND_COLORS = ["#FFFFFF", "var(--v-vflBeigeLight2-base)"];

export default {
  name: "MenopauseAssessmentReport",
  components: {
    MenopauseReportSymptoms,
    MenopauseReportPain,
    MenopauseReportMuscleLoss,
    MenopauseReportMskHealth,
    MenopauseReportHeader,
    ReportFooter
  },
  props: {
    results: Object,
    assessmentId: String,
    reportContext: {
      type: String,
      required: true,
      validator: value => ["assessment", "archive"].includes(value)
    }
  },
  computed: {
    ...mapGetters(["demoFeatures"]),
    assessmentType() {
      return assessmentTypes.menopauseAssessment;
    },
    hasPain() {
      if (!this.results?.pain?.areas) {
        return false;
      }

      return Object.keys(this.results.pain.areas).length > 0;
    },
    hasSymptoms() {
      const symptoms = this.results?.symptoms?.symptoms;
      const NONE_ANSWER = 99;

      if (!Array.isArray(symptoms)) {
        return false;
      }

      if (symptoms.length === 1 && symptoms[0] === NONE_ANSWER) {
        return false;
      }

      return symptoms.length > 0;
    },
    visibleSections() {
      let sectionNumber = 0;

      return REPORT_SECTION_CONFIG.filter(section =>
        section.isVisible(this)
      ).map(section => {
        const config = {
          ...section,
          sectionNumber,
          backgroundColor:
            section.id === "header"
              ? null
              : BACKGROUND_COLORS[(sectionNumber - 1) % 2],
          props:
            section.id === "pain" ? { assessmentId: this.assessmentId } : {}
        };
        sectionNumber++;
        return config;
      });
    },
    useFigmaIframe() {
      return (
        this.demoFeatures && import.meta.env.VITE_NODE_ENV === "production"
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.iframe-container {
  grid-column: 1/-1;
  min-height: calc(100vh - var(--topbar-height));
  position: relative;

  iframe {
    border: none;
    display: block;
    height: 100%;
    width: 100%;
  }
}

section {
  margin-left: -48px;
  margin-right: -48px;
  margin-top: -16px;

  @media (max-width: 1000px) {
    margin-left: -16px;
    margin-right: -16px;
  }
}
</style>
